@import '../../assets/scss/extends.scss';
@import "../../assets/scss/mixins";

section.panambi-opinions {
	@include section-padding(160px, 120px, 80px);
	background-color: var(--background-01);
	margin: 0;
	padding-left: 0;
	padding-right: 0;

	.container-fluid {
		@extend .container-fluid-left-gap;
	}

	h2 {
		@include responsive-margin(80px, 120px, 32px);
		text-align: center;
	}

	.panambi-swiper {
		@extend .left-gap;

		.swiper-slide {
			max-width: 468px;

			@media (min-width: 576px) and (max-width: 991.98px) {
				max-width: 360px;	
			}
					
			@media (max-width: 575.98px) {
				max-width: 242px;
			}
		}
	}
}