@import "../../assets/scss/extends";
@import "../../assets/scss/gradients";
@import "../../assets/scss/shadows";

.panambi-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	user-select: none;

	&.primary-btn,
	&.secondary-btn {
		border-radius: 50px;
		min-width: 196px;
		padding: 16px 20px;
		text-decoration: none;

		&:active {
			background: map-get($gradients, primary-button-pressed);
			color: var(--white);
			border: none;
		}

		&.disabled {
			background: unset;
			background-color: var(--surface);
			border: 1px solid var(--borders);
			color: var(--text-light);

			&:hover {
				box-shadow: initial;
			}
		}

		&:hover {
			box-shadow: map-get($shadows, btn-primary);
		}

		@media (max-width: 991.98px) {
			padding: 12px 20px;
		}

		@media (min-width: 576px) and (max-width: 991.98px) {
			min-width: 177px;
		}

		@media (max-width: 575.98px) {
			min-width: 100%;
		}
	}

	&.primary-btn {
		background: map-get($gradients, primary-button);
		color: var(--white);
	}

	&.secondary-btn {
		background: initial;
		background-color: var(--white);
		border: 1px solid var(--accent-2);
		color: var(--accent);
	}

	&.social-btn {
		@extend .rounded;
		background-color: var(--secondary);
		height: 56px;
		width: 56px;

		i {
			height: 32px;
			width: 32px;
		}

		@media (min-width: 576px) and (max-width: 991.98px) {
			height: 48px;
			width: 48px;

			i {
				height: 24px;
				width: 24px;
			}
		}

		@media (max-width: 575.98px) {
			height: 32px;
			width: 32px;

			i {
				height: 16px;
				width: 16px;
			}
		}
	}
}