@import "../../assets/scss/extends";

.panambi-icon {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    user-select: none;

    i {
      background-size: cover;
    }
    
  // Variant 1
  &.transparent-icon {
    background-color: transparent;
    height: 72px;
    width: 72px;

    i {
      background-size: cover;
      height: inherit;
      width: inherit;
      
      &::before {
        content: "";
      }
    }
  
    @media (max-width: 575.98px) {
      height: 40px;
      width: 40px;
    }
  }

  // Variant 2
  &.blue-rounded-icon {
    @extend .rounded;
    background-color: var(--primary);
    height: 64px;
    width: 64px;

    i {
      background-size: cover;
      height: 32px;
      width: 32px;

      &::before {
        content: "";
      }
    }

    @media (min-width: 576px) and (max-width: 991.98px) {
      height: 48px;
      width: 48px;

      i {
        background-size: cover;
        height: 24px;
        width: 24px;

        &::before {
          content: "";
        }
      }
    }
  
    @media (max-width: 575.98px) {
      height: 32px;
      width: 32px;

      i {
        background-size: cover;
        height: 16px;
        width: 16px;

        &::before {
          content: "";
        }
      }
    }
  }

  // Variant 3
  &.white-rounded-icon {
    height: 72px;
    width: 72px;

    i {
      background-size: cover;
      height: 34px;
      width: 34px;

      &::before {
        content: "";
      }
    }

    @media (min-width: 576px) and (max-width: 991.98px) {
      height: 64px;
      width: 64px;

      i {
        background-size: cover;
        height: 30px;
        width: 30px;

        &::before {
          content: "";
        }
      }
    }
  
    @media (max-width: 575.98px) {
      height: 48px;
      width: 48px;

      i {
        background-size: cover;
        height: 23px;
        width: 23px;

        &::before {
          content: "";
        }
      }
    }
  }

  // Variant 4
  &.benefit-icon {
    height: 48px;
    width: 48px;

    i {
      background-size: cover;
      height: 24px;
      width: 24px;

      &::before {
        content: "";
      }
    }
  
    @media (max-width: 575.98px) {
      height: 32px;
      width: 32px;

      i {
        background-size: cover;
        height: 16px;
        width: 16px;

        &::before {
          content: "";
        }
      }
    }
  }

  &.white-rounded-icon,
  &.benefit-icon {
    @extend .rounded;
    background-color: var(--white);
  }
}
