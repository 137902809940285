
.panambi-benefits-item {
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin-bottom: 40px;
  width: 360px;

  @media (min-width: 576px) and (max-width: 991.98px) {
    max-width: 300px;
    width: 100%;
  }

  @media (max-width: 575.98px) {
    width: 100%;
  }
}