@import '../../assets/scss/_extends.scss';
@import "../../assets/scss/_gradients.scss";

section.panambi-newsletter-banner,
section.panambi-spotify-banner {
	position: relative;
	padding: 0;

	&::before {
		background: url("https://i.postimg.cc/1z8VBmjG/banner-bg-large.webp");
		background-color: #C93F6B;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		@media (min-width: 576px) and (max-width: 991.98px) {
			background-image: url("https://i.postimg.cc/52NCHq5k/banner-bg-medium.webp");
		}
	
		@media (max-width: 575.98px) {
			background-image: url("https://i.postimg.cc/vHy9yd4y/banner-bg-small.webp");
		}
	}
	
	.container-fluid {
		@extend .container-fluid-left-gap;
		position: relative;

		@media (max-width: 575.98px) {
			padding: 0;
		}
	}

	.banner-content {
		@extend .left-gap;
		display: flex;
		justify-content: space-between;
		column-gap: 60px;
		
		@media (min-width: 576px) and (max-width: 991.98px) {
			column-gap: 32px;
		}

		@media (max-width: 575.98px) {
			flex-direction: column;
			column-gap: initial;
			margin: 0;
			padding: 0;
		}

		.banner-col-left {
			padding: 56px 0;

			.text-display {
				color: white;

				@media (min-width: 576px) and (max-width: 991.98px) {
					font-size: 38px;
					line-height: 46px;
				}

				@media (max-width: 575.98px) {
					text-align: center;
				}
			}

			@media (max-width: 575.98px) {
				order: 1;
				
				padding-left: 16px;
				padding-right: 16px;
			}
		}

		.banner-col-right {
			.banner-img {
				max-width: 360px;
				user-select: none;

				@media (min-width: 576px) and (max-width: 991.98px) {
					max-width: 216px;
				}
				
				@media (max-width: 575.98px) {
					max-width: initial;
					width: 100%;
				}
			}
		}
	}
}

section.panambi-spotify-banner {
	.banner-content {
		.panambi-btn {
			margin-top: 48px;

			.panambi-icon {
				i {
					height: 24px;
					width: 24px;
				}
			}

			@media (min-width: 576px) and (max-width: 991.98px) {
				margin-top: 24px;
			}
		}
	}	
}