@import "../../assets/scss/mixins";

footer {
    @include section-padding(80px, 40px, 32px);
    background-color: var(--primary-pressed);
    color: var(--white);
    
    .col {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 32px;
        }
    }

    h4 {
        margin-bottom: 12px;

        @media (min-width: 992px) {
            margin-bottom: 16px;
        }
    }
    
    ul {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;

        li:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    a {
        color: inherit;
        text-decoration: none;
        background-color: transparent;
        border: none;
        outline: none;

        &:hover {
            color: inherit;
        }
    }

    .logo-copy-row {
        text-align: center;
        
        picture {
            margin-bottom: 16px;
        }
    }

    @media (min-width: 576px) {
        .col {
            margin-bottom: 0;

            &:last-child {
                margin-bottom: 0px;
            }
        }

        div.row:first-child {
            margin-bottom: 40px;
            margin-right: 0;
            margin-left: 0;
            justify-content: space-between;
        }

        div.row>div.col {
            width: fit-content;
        }
    }

    @media (min-width: 992px) {
        div.row:first-child {
            margin-bottom: 80px;
        }

        div.row>div.col {
            width: fit-content;
        }

        ul li:not(:last-child) {
            margin-bottom: 12px;
        }
    }
}
