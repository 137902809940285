@import "../../assets/scss/shadows";

.panambi-paid-card {
	background-color: var(--white);
	border-radius: 8px;
	box-shadow: map-get($shadows, card-on-white-1);
	
	a {
		color: inherit;
		text-decoration: none;

		&:hover,
		&:visited {
			color: inherit;
		}
	}

	.card-img {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		width: 100%;
	}

	.card-body {
		display: flex;
		flex-direction: column;
		padding: 24px 16px;
		text-align: center;

		.card-name {
			height: 56px;
			margin-bottom: 48px;
			text-align: center;

			@media (min-width: 576px) and (max-width: 991.98px) {
				margin-bottom: 32px;
			}
	
			@media (max-width: 575.98px) {
				margin-bottom: 8px;
			}
		}

		.card-details {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			
			.panambi-icon {
				padding-left: 16px;
				padding-right: 8px;
				
				@media (max-width: 991.98px) {
					padding-left: 8px;
					padding-right: 4px;
				}

				i {
					height: 24px;
					width: 24px;

					@media (max-width: 575.98px) {
						height: 18px;
						width: 18px;
					}
				}
			}
		}

		@media (max-width: 575.98px) {
			padding: 8px 8px;
		}
	}
}