@import "../../assets/scss/gradients";
@import "../../assets/scss/mixins";

section.panambi-gradient-cover {
  @include section-padding(236px, 120px, 80px);
  background: map-get($gradients, header-02);
  color: var(--white);

  .container-lg {
    display: flex;
    column-gap: 80px;

    @media (min-width: 576px) and (max-width: 991.98px) {
      column-gap: 60px;
    }

    @media (max-width: 575.98px) {
      flex-direction: column;
      row-gap: 32px;
    }
  }

  .gradient-cover-details {
    h1 {
      @include responsive-margin(32px, 32px, 24px);
    }
  }
}