
section.panambi-navbar {
    background-color: var(--white);

    nav.navbar {
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
        height: 64px;
        background-color: var(--white);
        user-select: none;
        
    
        &>.container-lg {
            margin: 0;
            max-width: none;
            padding: 0;
        }
    
        .navbar-nav {
            .nav-link, .nav-item {
                &:hover {
                    color: var(--primary); 
                }
            }
    
            .dropdown-toggle{
    
                &::after {
                    display: inline-block;
                    margin-left: 0.255em;
                    content: "";
                    border-bottom: none;
                    border-right: 0.3em solid transparent;
                    border-top: 0.3em solid;
                    border-left: 0.3em solid transparent;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
    
                &.show {
                    color: var(--primary);
    
                    &::after {
                        border-bottom: 0.3em solid;
                        border-right: 0.3em solid transparent;
                        border-top: none;
                        border-left: 0.3em solid transparent;
                        top: 24px;
                    }
                }
            }
    
    
            .dropdown-menu {
                border: none;
                padding: 0;
                margin: 0;
            }
    
            .nav-link, .dropdown-item {
                color: var(--text-light);
                padding: 12px 32px;
            }
            
    
            .dropdown-item:hover {
                background-color: transparent;
            }
        }
    
        @media (min-width: 768px) {
            height: 92px;
    
            .container-lg {
                padding: 0;
            }
    
            .navbar-nav {
                padding: 16px 0;
                display: flex;
                gap: 16px;
                flex-direction: row;
    
                .nav-link {
                    padding: 10px;
                }
    
                .dropdown-item {
                    padding: 12px 16px;
                }
            }
    
        }
    
    
        // Mobile Menu changes to full height
        @media (max-width: 767.98px) {
            .navbar-collapse {
                position: fixed;
                top: 64px;
                left: 0;
                right: 0;
                bottom: 0;
                overflow-y: hidden;
                background-color: var(--white);
                transition: all 300ms linear;
    
                .nav-link, .dropdown-item {
                        text-align: end;
                }
    
                &.collapsing {
                    transition: height 300ms linear;
    
                    .navbar-nav {
                        height: 100vh;
                    }
                }
    
                &.show {
                    min-height: 100vh;
                }
            }
        }
    }
    
    button.navbar-toggler {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        border: none!important;
        height: 1.5em;
        padding: 1px 6px;
    
        &:focus {
            border: none;
            box-shadow: none!important;
        }
    
        span {
            display: block;
            width: 24px;
            height: 2.5px;
            background-color: var(--text);
            border-radius: 4px;
            transform-origin: center;
            transition: all 300ms ease-out;
        }
    
        &:not(.collapsed){
            span:nth-child(1) {
                transform: translateY(7.5px) rotate(-45deg);
            }
    
            span:nth-child(2) {
                opacity: 0;
            }
    
            span:nth-child(3) {
                 transform: translateY(-7.5px) rotate(45deg);
            }
        } 
    }
}
