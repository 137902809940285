@import "../../assets/scss/shadows";

.panambi-free-card {
	display: flex;
	background-color: var(--white);
	border-radius: 8px;
	box-shadow: map-get($shadows, card-on-white-1);
	position: relative;

	.card-body {
		display: flex;
		flex-direction: column;
		padding-top: 80px;
		padding-bottom: 80px;
		text-align: center;

		.card-name {
			margin-bottom: 40px;
			text-align: center;
		
			@media (min-width: 576px) and (max-width: 991.98px) {
				margin-bottom: 32px;
			}
		
			@media (max-width: 575.98px) {
				margin-bottom: 24px;
			}
		}

		iframe {
			height: 405px;
			user-select: none;
			width: 100%;

			@media (max-width: 575.98px) {
				height: 207px;
			}
		}

		@media (max-width: 991.98px) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		@media (max-width: 575.98px) {
			padding-top: 16px;
		}
	}
}