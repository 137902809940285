@import "../../assets/scss/gradients";
@import "../../assets/scss/mixins";

section.panambi-my-values {
  @include section-padding(160px, 120px, 80px);

  .values-list {
    row-gap: 40px;

    @media (min-width: 576px) and (max-width: 991.98px) {
      row-gap: 32px;
    }

    @media (max-width: 575.98px) {
      row-gap: 32px;
    }
  }

  h2 {
    @include responsive-margin(40px, 32px, 56px);
  }
}