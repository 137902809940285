
.container-fluid-left-gap {
  margin: 0;
	padding-right: 0;
}

.left-gap {
  margin: 0;
	margin-left: calc((100% - 1320px)/2);

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    margin-left: calc(var(--bs-gutter-x) * .5);
  }

  // Small devices (landscape phones, less than 768px)
  @media (min-width: 576px) and (max-width: 767.98px) {
    margin-left: initial
  }

  // Medium devices (tablets, less than 992px)
  @media (min-width: 768px) and (max-width: 991.98px) {
    margin-left: initial;
  }

  // Large devices (desktops, less than 1200px)
  @media (min-width: 992px) and (max-width: 1199.98px) {
    margin-left: calc((100% - 960px)/2);
  }

  // X-Large devices (large desktops, less than 1400px)
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    margin-left: calc((100% - 1140px)/2);	
  }
}

.rounded {
  border-radius: 50%;
}