$primary-button-purple: #df66ea;

form.us-form {
    gap: 24px;

    @media (min-width: 992px) {
        gap: 32px;
    }

    input,
    textarea {
        border: 1px solid var(--background-03);
        border-radius: 4px;
        padding: 16px;

        &:focus {
            outline: none;
            outline: 1px solid $primary-button-purple;
        }
    }

    textarea {
        resize: none;
    }

    label {
        gap: 4px;
        display: flex;
        flex-direction: column;

        &:focus-within {
            color: var(--accent) !important;
        }

        &.labelError {
            display: flex;
            flex-direction: column;
            color: var(--error) !important;

            input {
                border: 1px solid var(--error);

                &:focus {
                    outline: none;
                    outline: 1px solid var(--error);
                }
            }
        }
    }

    .panambi-btn.primary-btn {
        max-width: fit-content;
        align-self: end;
    }

    .panambi-btn {
        margin-top: 48px;

        .panambi-icon {
            height: 0;
            width: fit-content;

            i {
                height: 24px;
                width: 24px;
            }
        }

        @media (min-width: 576px) and (max-width: 991.98px) {
            margin-top: 24px;
        }
    }
}
