@import "../../assets/scss/mixins";

section.conferences-me {
    @include section-padding(160px, 120px, 80px);
    background: var(--background-01);

    h2 {
        @include responsive-margin(40px, 32px, 24px);
    }

    p:last-child {
        margin-bottom: 40px;
    }

    .row:last-child .col:first-child {
        z-index: 1;
    }
}

