@import "../../assets/scss/mixins";

section.panambi-about-us {
  @include section-padding(160px, 120px, 80px);
  background-color: var(--header-01);
  padding-bottom: 0;

  @media (min-width: 576px) and (max-width: 991.98px) {
    padding-bottom: 0;
  }

  @media (max-width: 575.98px) {
    padding-bottom: 0;
  }

  h1 {
    @include responsive-margin(40px, 32px, 24px);
  }

  img {
    @media (max-width: 575.98px) {
      margin-top: 40px;
    }
  }
  
  article {
    @include responsive-margin(40px, 32px, 32px);
    
    p:last-child {
      color: var(--secondary);
    }
  }

  .about-us-msj {
    margin-bottom: 8px;
  }

  .row:last-child .col:first-child {
    z-index: 1;
  }
}