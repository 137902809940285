@import "../../assets/scss/mixins";

section.panambi-manual {
  @include section-padding(160px, 120px, 80px);

  h2 {
    @include responsive-margin(40px, 32px, 24px);
  }

  img {
    @media (max-width: 575.98px) {
      margin-top: 40px;
    }
  }

  p {
    margin: 0;
  }

  .manual-msj {
    @include responsive-margin(16px, 8px, 8px);
  }
}