@import "../../assets/scss/mixins";

.panambi-faqs-item {
  &.accordion-item {
    @include responsive-margin(24px, 12px, 12px);
  }

  .accordion-body,
  .accordion-button {
    border-bottom: 1px solid var(--borders);
    border-radius: 8px;
    padding: 52px 60px;
    
    @media (min-width: 576px) and (max-width: 991.98px) {
      padding: 32px 40px;
    }
  
    @media (max-width: 575.98px) {
      padding: 24px 24px;
    }
  }

  .accordion-collapse.collapse.show {
    background-color: transparent;
  }

  .accordion-body {
    background-color: var(--white);
  }
  
  .accordion-button {
    display: flex;
    justify-content: space-between;

    &:not(.collapsed) {
      background-color: initial;
      box-shadow: none;
      color: initial;
    }

    &:focus {
      border-color: var(--borders);
      box-shadow: unset;
    }

    &::after,
    &:not(.collapsed)::after {
      display: none;
      background-image: unset;
    }
  }
}

.accordion-flush .accordion-item {
  border-radius: 8px; 
}