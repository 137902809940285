@import "../../assets/scss/mixins";

.panambi-faqs-custom-btn {
  .panambi-icon {
    i {
      height: 48px;
      width: 48px;
      
      @media (min-width: 576px) and (max-width: 991.98px) {
        height: 32px;
        width: 32px;
      }

      @media (max-width: 575.98px) {
        height: 24px;
        width: 24px;
      }
    }
  }
}