@import "../../assets/scss/extends";

.panambi-opinion-card {
	background-color: var(--white);
	border-radius: 8px;
	cursor: pointer;
	height: 274px;
	padding: 24px 32px;
	user-select: none;
	width: 468px;

	@media (min-width: 576px) and (max-width: 991.98px) {
		height: 288px;
		width: 360px;	
	}
			
	@media (max-width: 575.98px) {
		height: 216px;
		padding: 16px 16px;
		width: 242px;
	}

	.card-header {
		display: inline-flex;
		align-items: flex-start;
		margin-bottom: 24px;

		.card-user-avatar {
			@extend .rounded;
			display: flex;
			align-items: center;
			background-color: var(--primary);
			color: var(--white);
			line-height: initial;
			padding: 12px 11px;
		}

		.card-user-detail {
			padding-left: 16px;

			@media (max-width: 575.98px) {
				padding-left: 8px;

				h4 {
					font-size: 15px;
				}
			}
		}

		.card-stars {
			.panambi-icon {
				i {
					height: 24px;
					width: 24px;

					@media (max-width: 575.98px) {
						height: 16px;
						width: 16px;
					}
				}
			}
		}

		@media (max-width: 575.98px) {
			margin-bottom: 16px;
		}
	}
	
	.card-body {
		.card-opinion {
				height: 149px;
				margin: 0;
				text-overflow: ellipsis;

				&::before,
				&::after {
					content: '"';
				}

				@media (max-width: 991.98px) {
					height: 126px;
				}
		}
	}
}