@import "../../assets/scss/mixins";

section.panambi-faqs {
  @include section-padding(160px, 120px, 80px);
  background-color: var(--surface);

  .faqs-header {
    @include responsive-margin(80px, 32px, 32px);
  }

  .accordion-flush {
    .accordion-item {
      border-radius: 8px;

      .accordion-button,
      .accordion-button.collapsed {
        border-radius: 8px;
      }
    }
  }
  
}