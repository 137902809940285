@import "../../assets/scss/_gradients.scss";

.panambi-benefits-list {
  display: block;
  flex-direction: column;
  
  @media (min-width: 576px) and (max-width: 991.98px) {
    row-gap: 32px;
  }

  @media (max-width: 575.98px) {
    row-gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}