@import "../../assets/scss/gradients.scss";
@import "../../assets/scss/mixins";

section.conferences-cover {
    background: map-get($gradients, header-03);
    @include section-padding(160px, 120px, 80px);

    .text-container {
        row-gap: 24px;
        margin-bottom: 24px;

        .headline-container {
            p {
                margin-bottom: 8px;
            }
        }

        @media (min-width:576px) {
            row-gap: 32px;
            margin-bottom: 80px;
        }

        @media (min-width:992px) {
            row-gap: 40px;
            margin-bottom: 120px;
        }

        p {
            margin: 0;
        }
    }

    picture {
        @media (max-width: 575.98px) {
            margin-top: 40px;
        }
    }
}