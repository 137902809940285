@import "../../assets/scss/mixins";

section.panambi-about-me {
  @include section-padding(160px, 120px, 80px);
  background-color: var(--background-02);

  h2 {
    @include responsive-margin(40px, 32px, 24px);
  }

  img {
    @media (max-width: 575.98px) {
      margin-top: 40px;
    }
  }

  .about-me-msj {
    @include responsive-margin(16px, 8px, 8px);
  }

  .panambi-btn {
    margin-top: 120px;

    @media (min-width: 576px) and (max-width: 991.98px) {
      margin-top: 80px;
    }
  
    @media (max-width: 575.98px) {
      margin-top: 32px;
    }
  }
}