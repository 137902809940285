@import "../../assets/scss/mixins";

section.panambi-done-courses,
section.panambi-next-courses {
	background-color: var(--surface);
	padding-bottom: 160px;

	h2 {
		@include responsive-margin(40px, 32px, 24px);
	}

	p {
		@include responsive-margin(80px, 48px, 32px);
	}

	.courses-list {
		row-gap: 80px;

		@media (max-width: 575.98px) {
			row-gap: 32px;
		}
	}

	.courses-cta-btn {
		display: flex;
		justify-content: center;

		.panambi-btn {
			margin-top: 120px;

			@media (min-width: 576px) and (max-width: 991.98px) {
				margin-top: 80px;
			}
	
			@media (max-width: 575.98px) {
				margin-top: 32px;
			}
		}
	}
}

section.panambi-done-courses {
	padding-top: 200px;
}

section.panambi-next-courses {
	padding-top: 0;
}