@import "../../assets/scss/gradients.scss";
@import "../../assets/scss/mixins";

section.home-cover {
    background: map-get($gradients, header-01);
    @include section-padding(160px, 120px, 80px);

    .text-container {
            row-gap: 24px;
            margin-bottom: 32px;

            @media (min-width:576px) {
                row-gap: 32px;
                margin-bottom: 92px;
            }

            @media (min-width:992px) {
                row-gap: 40px;
                margin-bottom: 120px;
            }

            p {
                margin: 0;
            }
    }

    picture {
        .img-home-cover {
            @media (max-width: 575.98px) {
                margin-top: 40px;
            }
        }
    }
}